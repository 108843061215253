import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { navigate } from 'gatsby-link';
import { useTreatments } from '@splitsoftware/splitio-react';
import { Billboard, CustomList, Section, Button } from '@hagerty/react-components';

import { Split, Treatment } from 'src/constants/Split';
import { isReady } from 'src/components/SplitContext/isReady';
import { SiteDisclaimer } from 'src/components/Disclaimers';
import { MainFooter } from 'src/features/footer';

const meta = {
  title: 'Welcome to Hagerty Drivers Club',
  description:
    'There’s regular car life, then there’s Hagerty Drivers Club car life. By joining the Club, you’ve unlocked everything you need to take owning, driving and loving cars to the next level.',
};

const billboard = {
  category: 'Hagerty Drivers Club®',
  heading: 'You’re on the list: Join us for VIP access to exclusive experiences',
  lead:
    'As a Hagerty Drivers Club member, you’re a part of the world’s largest community of automotive enthusiasts. Alongside likeminded car lovers, join in for VIP access to in-person and digital experiences.',
};

const listContent = [
  'Member meet-ups',
  'Cars & Caffeine™',
  'Track days',
  'Seminars',
  'Livestreams',
  'Driving tours and rallies',
  'Parties',
  'Movie nights',
  'And more',
];

const InsuranceExperiences = () => {
  const splitIsReady = isReady();
  const treatments = useTreatments([Split.FeatureInsurancePages]);
  const hasInsurancePages = treatments[Split.FeatureInsurancePages].treatment === Treatment.On;

  useEffect(() => {
    if (splitIsReady && !hasInsurancePages) navigate('/');
  }, [splitIsReady]);

  return (
    <>
      <Helmet>
        <title>{meta.title}</title>
        <meta name="description" content={meta.description} />
      </Helmet>
      <main>
        <Billboard
          categoryText={billboard.category}
          mainHeadingText={<h1 className="billboard__heading text-display_1 no-padding">{billboard.heading}</h1>}
          leadText={billboard.lead}
          video={{
            videoID: 'Hszt5CHQkZo',
            videoPlatform: 'youtube',
          }}
        />
        <div className="container container_center">
          <div className="grid grid-wrap gutters_direct grid_align-center">
            <div className="col xs-size_1-of-1 max-width_content">
              <div className="body content">
                <Section>
                  <h2>We’re talking:</h2>
                  <CustomList bulletType="check" listItems={listContent} />
                  <p>
                    And when we say VIP, we mean VIP. Get special access to concours events, members-only benefits,
                    private tours, reserved parking and savings on tickets to tons of events.
                  </p>
                  <br />
                  <Button
                    iconRight={{ icon: 'ArrowRight16' }}
                    testId="insurance-experiences-button"
                    href={`${process.env.HDC_HAGERTY_DRIVERS_CLUB_ROOT_URL}/events/`}
                  >
                    See the calendar
                  </Button>
                </Section>
              </div>
              <SiteDisclaimer />
            </div>
          </div>
        </div>
        <MainFooter />
      </main>
    </>
  );
};

export default InsuranceExperiences;
